function setupImageScroller(el) {
  document.addEventListener("load", function () {
    var scrollTimer = el.getAttribute("data-image-scroller-timer");
    el.querySelector("div.game-scroller-mainDiv").style["animation-duration"] = scrollTimer + "s";
  });

  // Accessible scroller, pause on space bar
  // KEYCODES: 32 - space
  el.addEventListener('keydown', (e: KeyboardEvent) => {
    if (e.keyCode === 32) {
      e.preventDefault();
      e.target.querySelector("div.game-scroller-mainDiv").classList.toggle('is-paused');
    }
  });
}

const allImageScrollers = Array.from(document.querySelectorAll(".js-game-scroller-wrap"));
allImageScrollers.forEach(setupImageScroller);
